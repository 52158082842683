/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Arrow from 'img/icon/arrow-right-circled-contrast.inline.svg'

// Libraries
import parse from 'html-react-parser'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import NationsSeeker from 'components/NationsSeeker'

// Elements
import ImageBackground from 'components/elements/ImageBackground'
import HeroAlt from 'components/elements/HeroAlt'
import HeroDescription from 'components/elements/HeroDescription'
import BlockDefault from 'components/elements/BlockDefault'
import BackgroundDescription from 'components/elements/BackgroundDescription'

// Data
import homeInfo from 'src/graphql/homeInfo'
import Infographic from '../components/Infopgrahic/Infographic'

const FeaturedImage = styled(ImageBackground)`
  padding-top: 50%;
  border-radius: 35px;
  overflow: hidden;
`

const Icon = styled(Arrow)`
  height: 30px;
  width: 30px;
`

const Block = styled(BlockDefault)`
  @media (min-width: 992px) {
    margin-top: -200px;
  }

  @media (max-width: 991px) {
    margin-top: -50px;
  }

  @media (max-width: 575px) {
    margin-top: -35px;
  }
`

const Item = styled.div`

  h3 { 
    color: ${({ theme }) => theme.color.text.contrast};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content

      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        meta {
          description
        }

        faq_questions {
          question
          answer
        }

        extra {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          description
          questions_extra {
            question
            answer
          }
          background_description
        }

        show_infographic
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  const homeFields = homeInfo.get()
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [indexOpen, setIndexOpen] = React.useState(0)

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);

    const item = document.getElementById(`tabitem-${index} `)
    // Scroll to the top of the TabItem when its h3 is clicked
    window.scrollTo({
      top: item.offsetTop - 1000,
      behavior: 'smooth'
    })
    // Your other toggleAccordion logic here
  }

  const toggleAccordionExtra = (index) => {
    setIndexOpen(indexOpen === index ? -1 : index);

    const item = document.getElementById(`extra-tabitem-${index} `)
    // Scroll to the top of the TabItem when its h3 is clicked
    window.scrollTo({
      top: item.offsetTop - 1000,
      behavior: 'smooth'
    })
    // Your other toggleAccordion logic here
  }




  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <section>
        <HeroAlt small image={acf.banner.image}>
          <HeroDescription className="d-flex align-items-center justify-content-start h-100">
            {parse(acf.banner.title)}
          </HeroDescription>
        </HeroAlt>
      </section>

      <section>
        <div className="container pb-5 mt-n5">
          <div className="row">
            <div className="col-lg-4" />
            <div className="col-lg-8">
              <BlockDefault className="mt-lg-n5" bigBorderRadius>
                {acf.faq_questions && acf.faq_questions.length > 0 ? (
                  acf.faq_questions.map((question, index) => {
                    const status = activeIndex === index ? 'open' : 'closed'

                    return (
                      <Item key={index} className="mb-4">
                        <div
                          className="d-flex align-items-start justify-content-between"
                          onClick={() => toggleAccordion(index)}
                          role="button"
                        >
                          <ParseContent className="mr-3" content={question.question} />
                          <motion.div
                            initial="open"
                            variants={{
                              open: { rotate: 90 },
                              closed: { rotate: 0 },
                            }}
                            animate={status}
                          >
                            <Icon />
                          </motion.div>
                        </div>

                        <motion.div
                          initial="open"
                          variants={{
                            open: { opacity: 1, height: 'auto' },
                            closed: { opacity: 0, height: 0 },
                          }}
                          animate={status}
                          className="overflow-hidden"
                        >
                          <div className="mt-2">
                            <ParseContent content={question.answer} />
                          </div>
                        </motion.div>
                      </Item>
                    )
                  })
                ) : (
                  <ParseContent content={acf.meta.description} />
                )}
              </BlockDefault>
            </div>
          </div>
        </div>
      </section>

      {acf.extra.questions_extra && acf.extra.questions_extra.length > 0 && (
        <section>
          <div className="container">
            <BackgroundDescription className="mt-3" content={acf.extra.background_description} relative />

            <div className="row">
              <div className="col-lg-2" />
              <div className="col-lg-10">
                <FeaturedImage src={acf.extra.image} />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7">
                <Block bigBorderRadius>
                  {acf.extra.questions_extra.map((questionExtra, index) => {
                    const status = indexOpen === index ? 'open' : 'closed'

                    return (
                      <Item key={index} className="mb-4">
                        <div
                          className="d-flex align-items-start justify-content-between"
                          onClick={() => toggleAccordionExtra(index)} role="button"
                        >
                          <ParseContent content={questionExtra.question} className="mr-3" />
                          <motion.div
                            initial="open"
                            variants={{
                              open: { rotate: 90 },
                              closed: { rotate: 0 },
                            }}
                            animate={status}
                          >
                            <Icon />
                          </motion.div>
                        </div>
                        <motion.div
                          initial="open"
                          variants={{
                            open: { opacity: 1, height: 'auto' },
                            closed: { opacity: 0, height: 0 },
                          }}
                          animate={status}
                          className="overflow-hidden"
                        >
                          <div className="mt-2">
                            <ParseContent content={questionExtra.answer} />
                          </div>
                        </motion.div>
                      </Item>
                    )
                  })}
                </Block>
              </div>
              <div className="col-lg-5" />
            </div>
          </div>
        </section>
      )}

      {parse(acf.extra.description)[0].props.children !== 'empty' && (
        <section>
          <div className="container">
            <BackgroundDescription className="mt-3" content={acf.extra.background_description} relative />

            <div className="row">
              <div className="col-lg-2" />
              <div className="col-lg-10">
                <FeaturedImage src={acf.extra.image} />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7">
                <Block bigBorderRadius>
                  <ParseContent content={acf.extra.description} />
                </Block>
              </div>
              <div className="col-lg-5" />
            </div>
          </div>
        </section>
      )}

      <section>
        <div className="container mt-5 pt-5">
          <BackgroundDescription content={homeFields.acf.identity.background_description} relative />
        </div>
      </section>

      <section>
        <div className="container pb-5 mb-lg-5">
          <NationsSeeker fields={homeFields.acf.search} />
        </div>
      </section>

      {acf.show_infographic && (
        <Infographic />
      )}

    </Layout>
  )
}

export default PageTemplate
