/* eslint-disable import/no-unresolved */
import { graphql, useStaticQuery } from 'gatsby'

// Language
import { getLanguage } from 'services/language'

const home = {
  get() {
    const language = getLanguage()

    const {
      homeNL,
      homeEN
    } = useStaticQuery(graphql`
      {
        homeNL: wordpressPage(wordpress_id: {in: [2, 131]}, wpml_current_locale: {eq: "nl_NL"}) {
          ...homeContent
        }
  
        homeEN: wordpressPage(wordpress_id: {in: [2, 131]}, wpml_current_locale: {eq: "en_US"}) {
          ...homeContent
        }
      }
  
      fragment homeContent on wordpress__PAGE {
        path

        acf {
          banner_block {
            title
            description
            button {
              title
              url
            }
            info
          }

          search {
            title
            description
            background_description
            highlighted
            button {
              title
              url
            }
          }

          show_infographic

          identity {
            background_description
          }
        }
      }
    `)

    return language === 'nl_NL' ? homeNL : homeEN
  }
}

export default home